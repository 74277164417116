import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectsPage = () => (
    <Layout>
        <SEO title="Projects page"/>
        <p>This is the projects page</p>
    </Layout>
)

export default ProjectsPage
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: `90%`,
        padding: `1.45rem 1.0875rem`,
        
      }}
    >
      <h1 id="siteTitle">
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
      <nav>
        <ul>
          <li>
            <Link to="#services">Services</Link>
          </li>
          <li>
            <Link to="#aboutUs"> About</Link>
          </li>
          <li>
            <Link to="#process">Process</Link>
          </li>
          <li>
            <Link to="#contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
